.wrapper {
    @apply
        fixed
        top-0
        left-0
        flex
        w-full
        h-full
        p-8
        bg-white
        z-20;

    @apply
        md:absolute;
}

.inner {
    @apply
        md:my-auto
}
