/**
 * xsmall - 375px;
 */

.container-xsmall {
    @apply
        mx-auto;

    max-width: 20.83rem;
}


/**
 * small - 400px;
 */

.container-small {
    @apply
        mx-auto;

    max-width: 22.22rem;
}


/**
 * medium - 480px;
 */

.container-medium {
    @apply
        mx-auto;

    max-width: 26.66rem;
}


/**
 * large - 640px;
 */

.container-large {
    @apply
        mx-auto;

    max-width: 35.55rem;
}


/**
 * xlarge - 768px;
 */

.container-xlarge {
    @apply
        mx-auto;

    max-width: 42.66rem;
}


/**
 * xxlarge - 810px;
 */

.container-xxlarge {
    @apply
        mx-auto;

    max-width: 45rem;
}
