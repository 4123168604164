.button {
    @apply
        p-4
        rounded-20px
        text-sm
        font-semibold;

        box-shadow: inset 0px 4px 4px 50px rgba(0, 0, 0, 0);
        transition: box-shadow var(--animation-medium, 500ms) var(--animation-bezier, linear);

        &:hover {
            box-shadow: inset 0px 4px 4px 50px rgba(0, 0, 0, 0.15);
        }

        &:disabled {
            @apply
                cursor-not-allowed;

            box-shadow: inset 0px 4px 4px 50px rgba(255, 255, 255, 0.8);
        }
}

.buttonPrimary {
    @apply
        bg-primary
        text-secondary;
}

.buttonSecondary {
    @apply
        border
        border-primary
        bg-secondary
        text-primary;
}
