.errorImage {
    @apply
        mx-auto;

    max-height: 19.75rem;
    max-width: 19.75rem;
}

.description {
    @apply
        flex-shrink-0
        flex-grow-0
        mt-11
        text-center;
}
