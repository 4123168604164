.image {
    @apply
        max-w-full
        w-full
        mx-auto;

    min-height: 17rem;
}

.description {
    @apply
        mt-4
        mx-auto
        text-center;

    max-width: 17rem;
}

.ageInput {
    @apply
        w-full
        pt-2.5
        px-4
        pb-2
        rounded-lg;

    /* Firefox */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
