.button {
    @apply
        relative
        py-2
        px-4;
}

.buttonText {
    @apply
        relative
        text-xs
        z-20;

    transition: color var(--animation-slow, 250ms) var(--animation-bezier, linear);
}

.buttonTextAlt {
    @apply
        text-secondary;
}

.bubble {
    @apply
        absolute
        top-1/2
        left-1/2
        border-5
        rounded-full
        border-white
        bg-primary
        opacity-0
        z-10;

        width: 3.35rem;
        height: 3.35rem;
        transform: translateX(-50%) translateY(-50%) scale(0.5);
        transition:
            transform var(--animation-fast, 250ms) var(--animation-bezier, linear),
            opacity var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.bubbleShow {
    @apply
        opacity-100;

    transform: translateX(-50%) translateY(-50%) scale(1);
}
