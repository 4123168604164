.Chip {
    display:flex;
    align-items:center;
    background-color:white;
    box-shadow: 0px 0px 40px rgba(9, 36, 67, 0.12);

    @apply
        rounded-10px
        p-2
        space-x-2;
}

.ChipBadge {
    width:24px;
}

.ChipIcon {
    width:12px;
    height:12px;
}