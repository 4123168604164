.selectableCard {
    @apply
        relative
        border
        rounded-1250
        border-smoke;

    transition: border-color var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.selectableCardChecked {
    @apply
        border-primary;
}

.selectableCardDisabled {

    @apply
        border-smoke
        border-dashed
        cursor-not-allowed;

    * {
        @apply text-slate;
    }

    img {
        filter: grayscale(1);
        opacity: 0.5;
    }

}

.checkIcon {
    @apply
        absolute
        rounded-full
        text-secondary
        bg-primary
        -top-2
        -right-2
        opacity-0;

    padding: 7px;
    transform: scale(0.5);
    transition:
        opacity var(--animation-fast, 250ms) var(--animation-bezier, linear),
        transform var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.checkIconShow {
    @apply
        opacity-100;

    transform: scale(1);
}

.toggleButton {
    @apply
        absolute
        top-0
        left-0
        h-full
        w-full
        border-0
        bg-transparent
        text-transparent
        cursor-pointer;
}
