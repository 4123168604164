.age {
    @apply
        flex
        justify-between
        items-center
        py-8
        px-6;

    @apply
        md:rounded-3xl
        md:py-5
        md:bg-shade;
}

.score {
    @apply
        py-8
        px-6
        rounded-t-1250
        bg-white;

    @apply
        md:p-0
        md:mt-8
        md:rounded-none;
}
