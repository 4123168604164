.aside {
    @apply
        fixed
        top-0
        left-0
        w-full
        h-full
        p-7.5
        bg-white
        z-30;

    @apply
        md:absolute
        md:flex;
}

.inner {
    @apply
        flex
        flex-col
        justify-between
        h-full;

    @apply
        md:h-auto
        my-auto;
}

.heading {
    @apply
        mt-10;

    @apply
        md:mt-0;
}

.shareLinksWrapper {
    @apply
        my-auto space-y-4;

    @apply
        md:mt-8;
}

.shareButton {
    @apply
        flex
        items-center
        w-full
        p-4
        rounded-18px
        text-white
        font-semibold;

}

.download {
    @apply
        shareButton;

    color: #092443;
    border:  2px inset #5E7696;
}

.facebook {
    @apply
        shareButton;

    background: linear-gradient(86.77deg, #3278E0 0%, #54BCF9 100%);
}

.twitter {
    @apply
        shareButton;

    background: linear-gradient(268deg, #133146 0.03%, #000 100.03%);
}
