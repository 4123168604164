.wrapper {
    @apply
        flex;

    @apply
        md:p-8;
}

.inner {
    @apply
        flex
        flex-col
        flex-grow;

    @apply
        md:my-auto
        md:flex-row
        md:items-center;
}

.photo {
    @apply
        relative
        flex-grow
        bg-cover
        bg-center;

    @apply
        md:flex-shrink-0
        md:rounded-3xl
        md:overflow-hidden;

    @media screen and (min-width: 768px) {
        width: 21rem;
        height: 32rem;
    }
}

.card {
    @apply
        relative
        flex-shrink-0
        flex-grow-0
        p-8
        -mt-8
        rounded-t-2250
        bg-white
        text-center
        overflow-hidden;

    @apply
        md:rounded-none
        md:mt-0
        md:flex-shrink
        md:flex-grow;
}

.gradient {
    @apply
        absolute
        bottom-0
        left-0
        w-full
        pt-4
        px-4
        pb-12
        text-right;

    @apply
        md:top-0
        md:bottom-auto
        md:p-6;

    background: linear-gradient(0deg, rgba(9, 36, 67, 0.3) 0%, rgba(9, 36, 67, 0) 100%);

    @media screen and (min-width: 768px) {
        background: linear-gradient(180deg, rgba(9, 36, 67, 0.3) 0%, rgba(9, 36, 67, 0) 100%);
    }
}

.editButton {
    @apply
        border
        border-white
        rounded-15px
        py-2
        px-4
        text-sm
        text-white;
}

.button {
    flex-grow:1;
}