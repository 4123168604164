.wrapper {
    @apply
        relative
        hidden
        w-full
        pt-8
        px-8
        bg-white
        z-20;

    @apply
        md:block;
}

.inner {
    @apply
        flex
        justify-between
        items-center;

    height: 2.66rem;
}

.logo {
    width:auto;
    max-height:2.66rem;
}

.buttonIcon {
    @apply
        w-4
        h-4
        px-4
        -mr-4
        box-content;
}

.backButton {
    @apply
        flex
        items-center
        p-4
        ml-2
        hover:underline;
}
