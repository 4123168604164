.sep-heading {
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:1rem;
    max-width: 624px;

    hr {

        @apply bg-smoke;

        margin:0;
        flex-grow:1;
    }
}