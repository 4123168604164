.wrapper {
    @apply
        absolute
        top-0
        left-0
        flex
        flex-col
        items-center
        justify-center
        w-full
        h-full
        p-8
        text-center
        bg-white
        md:pt-0;
}

.lottie {
    @apply
        mx-auto
        pointer-events-none;

    max-width: 19.75rem;
    max-height: 19.75rem;
}

.fact {
    min-height:6rem;
}