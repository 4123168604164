.description {
    @apply
        mt-7
        pb-20;

    @apply
        md:mt-0
        md:pb-0;
}

.buttonWrapper {
    @apply
        fixed
        bottom-7.5
        left-0
        w-full
        px-7.5
        text-center;

    @apply
        md:relative
        md:bottom-auto
        md:left-auto
        md:mt-6;
}

.button {
    @apply
        w-full;

    @apply
        md:w-60;
}
