.header {
    @apply
        fixed
        top-0
        left-0
        flex
        justify-between
        items-center
        w-full
        text-center
        text-white
        overflow-hidden
        z-10;

    @apply
        md:absolute
        md:pt-6
        md:pr-4;

    background: linear-gradient(180deg, rgba(9, 36, 67, 0.3) 0%, rgba(9, 36, 67, 0) 100%);
}

.backButton {
    @apply
        p-4;

    @apply
        md:hidden;
}

.heading {
    @apply
        absolute
        top-1/2
        left-0
        w-full
        text-center
        text-white
        opacity-100
        pointer-events-none;

    transform: translateY(-50%);
}

.headingHide {
    @apply
        opacity-0;
}

.shareButton {
    @apply
        border
        border-white
        rounded-15px
        py-2
        px-4
        mr-4
        text-sm
        text-white
        opacity-0;

    @apply
        md:ml-auto
        md:mr-0;
}

.shareButtonShow {
    @apply
        opacity-100;
}

/**
 * Slider styles
 */
.slider {
    @apply
        fixed
        top-0
        left-0
        w-full
        h-full
        max-h-screen;

    @apply
        md:relative
        md:w-auto
        md:rounded-3xl
        md:overflow-hidden;

    transition: max-height var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.sliderShrink {
    max-height: 75vh;

    @apply
        md:max-h-full;
}
