$desktopWidth: 22.5rem;
$desktopHeight: 33.5rem;

.container {
    @apply
        relative
        w-screen
        h-full;

    @apply
        md:rounded-3xl
        md:overflow-hidden;

    @media screen and (min-width: 768px) {
        width: $desktopWidth;
        height: $desktopHeight;
    }
}

.cropContainer {
    @apply
        relative
        w-full
        h-full
        border-r
        border-white
        overflow-hidden;
}

.image {
    @apply
        absolute
        top-0
        left-0
        w-screen
        h-full
        bg-cover
        bg-center;

    @media screen and (min-width: 768px) {
        width: $desktopWidth;
        height: $desktopHeight;
    }
}

.track {
    @apply
        absolute
        w-full
        h-14;

    bottom: 8vh;

    @media screen and (min-width: 768px) {
        bottom: 3rem;
    }
}

.rangeInput {
    @apply
        block
        h-full
        w-full
        opacity-0
        cursor-pointer;

    -webkit-appearance: none;

    &:focus {
        outline: transparent;
    }

    // NOTE: Changed the range thumb size because IOS Safari mobile needs to be able to click the thumb before it can slide.
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;

        @apply
            w-16
            h-14;

        background: #ffffff;
    }

    &::-moz-range-thumb {
        -webkit-appearance: none;

        @apply
            w-16
            h-14;

        background: #ffffff;
    }

    &::-ms-thumb {
        -webkit-appearance: none;

        @apply
            w-16
            h-14;

        background: #ffffff;
    }
}

.scrub {
    @apply
        absolute
        top-0
        flex
        justify-between
        w-14
        h-14
        p-2.5
        rounded-full
        bg-white
        text-transparent
        pointer-events-none;

    transform: translateX(-50%);

    > img {
        max-width: .5rem;

        &:last-of-type {
            transform: scaleX(-1);
        }
    }
}

.hint {
    @apply
        absolute
        top-1/2;

    right: -3.75rem;
    filter: drop-shadow(3px 5px 2px #092443);
}
