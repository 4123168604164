.wrapper {
    @apply
        relative
        border
        border-smoke
        rounded-lg;
}

.label {
    @apply
        absolute
        -top-2
        left-3.5
        px-0.5
        bg-white
        text-slate
        z-10;
}

.validationIcon {
    @apply
        absolute
        top-1/2
        right-2
        text-improved;

    transform: translateY(-50%);
}
