.delay {
    display:flex;
    position:fixed;
    top:0;
    left:0;
    z-index:9999;
    width:100%;
    height:100%;
    background-color:theme('colors.darkOverlay');
    color: theme('colors.white');
    align-items:center;
    justify-content: center;
}