.imageContainer {
    @apply
        flex
        flex-grow
        justify-center
        items-center;
}

.examplePhoto {
    @apply
        w-full
        object-cover
        object-top
        overflow-hidden
        mx-auto;

    border-radius: 2rem;
    width: 14rem;
    height: 17rem;
}

.errorImage {
    @apply
        mx-auto;

    max-height: 19.75rem;
    max-width: 19.75rem;
}

.description {
    @apply
        flex-shrink-0
        flex-grow-0
        mt-8
        text-center;
}
