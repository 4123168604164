.wrapper {
    @apply
        fixed
        top-0
        left-0
        flex
        w-full
        h-full
        p-8
        bg-white;

    @apply
        md:absolute;
}

.inner {
    @apply
        relative
        flex
        flex-col
        justify-between
        w-full
        h-full
        m-auto;

    // max-width: 26.66rem;
    max-height: 36.5rem;
}

.actions {
    @apply
        absolute
        bottom-0
        left-0
        right-0
        flex-grow-0
        flex-shrink-0
        w-full
        mx-auto;

    @media screen and (min-width: 768px) {
        max-width: 20.83rem;
    }
}

.startButton {
    @apply
        absolute
        top-0
        left-0
        w-full;

    transition: opacity var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.questionnaireButtons {
    @apply
        grid
        grid-cols-2
        gap-3;

    transition: opacity var(--animation-fast, 250ms) var(--animation-bezier, linear);
}
