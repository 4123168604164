.wrapper {
    @apply
        fixed
        flex
        flex-col
        justify-between
        bg-white
        z-20;

    @apply
        md:justify-start;
}

.header {
    @apply
        relative
        flex
        justify-center
        items-center
        flex-grow;

    @media screen and (min-width: 768px) {
        max-height: 50%;
    }
}

.splashLogo {
    max-width:12.5rem;
    height:auto;
    position:relative;
}

.roundBackground {
    @apply
        absolute
        bottom-0
        rounded-full
        bg-primary;

    width: 200vw;
    height: 200vw;
}


.content {
    @apply
        flex-grow-0
        flex-shrink-0
        px-8
        pb-8;

    @apply
        md:pt-8
        md:my-auto;
}

.contentBlocked {
    @apply
        flex-grow-0
        flex-shrink-0
        text-center
        p-8;

    @apply
        md:my-auto;
}

.label {
    @apply
        flex
        justify-between
        items-center
        w-full
        py-2
        my-4
        cursor-pointer;
}

