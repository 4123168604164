.button {
    @apply
        px-4
        py-2
        -mx-4
        -my-2;

    @apply
        md:hidden;
}

.cross {
    @apply
        w-2.5;
}
