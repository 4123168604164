.inner {
    @apply
        md:flex
        md:h-full
        md:max-h-full
        md:overflow-hidden;
}

/**
 * Score styles
 */
.scoreCard {
    @apply
        relative
        flex-shrink-0
        flex-grow-0
        w-full
        rounded-t-2250
        bg-shade
        z-20;

    @apply
        md:flex-grow
        md:flex-shrink
        md:w-1/2
        md:pl-6
        md:pr-2
        md:rounded-none
        md:bg-transparent
        md:overflow-y-auto
        md:overflow-x-hidden;

    margin-top: 100vh;
    transition: margin var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.scoreCardShow {
    margin-top: 70vh;

    @apply
        md:mt-0
}
