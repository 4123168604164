.Radio {
    position:relative;
}

.RadioField {
    width:1px;
    height:1px;
    overflow:hidden;
    left:-9999px;
    opacity:0;
    position:absolute;

    &:checked + label:before{
        background-color:theme('colors.shade')
    }

}

.RadioLabel {
    display:flex;
    align-items:center;
    cursor:pointer;

    @apply
        p-2
        gap-2;

        &:before {
            content:'';
            position:absolute;
            z-index:-1;
            top:0;
            left:0;
            width:100%;
            height:100%;
            transition:background-color var(--animation-fast, 250ms) linear;

            @apply
                rounded-10px;
        }
}