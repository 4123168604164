
.input {
    @apply
        hidden;
}

.checkbox {
    @apply
        flex
        items-center
        justify-center
        p-1
        border-2
        border-primary
        text-primary;

    width: 1.44rem;
    height: 1.44rem;
    border-radius: 0.22rem;
}

.icon {
    @apply
        opacity-0;

    transition: opacity var(--animation-fast, 250ms) var(--animation-bezier, linear);
}

.iconShow {
    @apply
        opacity-100;
}
