.progressBar {
    @apply flex;

    > * + * {
        @apply ml-px;
    }
}

.singleBar {
    @apply flex-grow;

    height: 2px;
}
