.wrapper {
    @apply
        fixed
        top-0
        left-0
        flex
        flex-col
        w-full
        h-full
        bg-white
        z-30;

    @apply
        md:absolute;
}

.video {
    @apply
        m-auto
        rounded-3xl;

    transform: scaleX(-1);
}

.photoButton {
    @apply
        w-full;
}
