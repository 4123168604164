/**
 * Base classes
 */
.transitionAttributes {
    @apply
        pointer-events-none;

    transition:
        transform var(--animation-medium, 500ms) var(--animation-bezier, linear),
        opacity var(--animation-medium, 500ms) var(--animation-bezier, linear);
}


/**
 * Animate item in left to center.
 */

.a_slideInLeft {
    @apply
        opacity-0;

    transform: translateX(-10%);
}

.a_slideInLeftActive {
    @apply
        transitionAttributes
        opacity-100;

    transform: translateX(0%);
}

.a_slideInLeftDone {
    @apply
        opacity-100;

    transform: translateX(0%);
}


/**
 * Animate item out center to left.
 */

.a_slideOutLeft {
    @apply
        opacity-100;

    transform: translateX(0%);
}

.a_slideOutLeftActive {
    @apply
        transitionAttributes
        opacity-0;

    transform: translateX(-10%);
}

.a_slideOutLeftDone {
    @apply
        opacity-0;

    transform: translateX(-10%);
}
