/**
 * Vars to calculate the max width of the grid container.
 */
$maxNumberOfColumns: 6; // Max number of columns in the grid.
$columnSize: 89px; // The width of each column.
$columnGap: 18px; // The size of the column gaps.

// Calculate the max width.
$maxWidth: ($maxNumberOfColumns * $columnSize) + (($maxNumberOfColumns - 1) * $columnGap);

.wrapper {
    @apply
        flex
        flex-col
        h-full
        px-4
        mt-4
        -ml-4
        -mb-8
        overflow-hidden;

    @apply
        md:min-w-full
        md:px-0
        md:m-0
        md:mt-4
        md:overflow-auto;

    min-width: calc(100% + 2rem);

    @media screen and (min-width: 768px) {
        min-width: 0;
    }
}

.lifestyleSelector {
    @apply
        flex-grow
        flex-col
        max-w-full
        my-auto
        pt-2
        px-4
        mt-5
        gap-7.5
        -ml-4
        pb-28
        overflow-auto;

    @apply
        md:flex
        md:items-center
        md:px-0
        md:ml-0
        md:overflow-visible;

    min-width: calc(100% + 2rem);

    @media screen and (min-width: 768px) {
        min-width: 0;
    }
}

.questionGrid {
    @apply
        grid
        mx-auto
        justify-center;

    grid-gap: $columnGap;
    max-width: $maxWidth;

    @supports (grid-template-columns: repeat(auto-fit, minmax($columnSize, 1fr))) {
        grid-template-columns: repeat(auto-fill, minmax(clamp(4rem,$columnSize,100%), 1fr));
    }
}

.questionLabel {
    @apply
        pt-1.5
        text-dark
        text-center;
}

.image {
    @apply
        m-auto;

    width: 3.33rem;
    height: 3.33rem;
}
