/**
 * Base classes
 */

.choiceList {
    @apply
        absolute
        top-1/2
        left-1/2
        w-full;

    transform: translateX(-50%) translateY(-50%);
}

.transitionAttributes {
    @apply
        pointer-events-none;

    transition:
        transform var(--animation-medium, 500ms) var(--animation-bezier, linear),
        opacity var(--animation-medium, 500ms) var(--animation-bezier, linear);
}


/**
 * Animate item in right to center.
 */

.a_slideInRight {
    @apply
        opacity-0;

    transform: translateX(-40%) translateY(-50%);
}

.a_slideInRightActive {
    @apply
        transitionAttributes
        opacity-100;

    transform: translateX(-50%) translateY(-50%);
}

.a_slideInRightDone {
    @apply
        opacity-100;

    transform: translateX(-50%) translateY(-50%);
}


/**
 * Animate item in left to center.
 */

.a_slideInLeft {
    @apply
        opacity-0;

    transform: translateX(-60%) translateY(-50%);
}

.a_slideInLeftActive {
    @apply
        transitionAttributes
        opacity-100;

    transform: translateX(-50%) translateY(-50%);
}

.a_slideInLeftDone {
    @apply
        opacity-100;

    transform: translateX(-50%) translateY(-50%);
}


/**
 * Animate item out center to left.
 */

.a_slideOutLeft {
    @apply
        opacity-100;

    transform: translateX(-50%) translateY(-50%);
}

.a_slideOutLeftActive {
    @apply
        transitionAttributes
        opacity-0;

    transform: translateX(-60%) translateY(-50%);
}

.a_slideOutLeftDone {
    @apply
        opacity-0;

    transform: translateX(-60%) translateY(-50%);
}


/**
 * Animate item out center to right.
 */

.a_slideOutRight {
    @apply
        opacity-100;

    transform: translateX(-50%) translateY(-50%);
}

.a_slideOutRightActive {
    @apply
        transitionAttributes
        opacity-0;

    transform: translateX(-40%) translateY(-50%);
}

.a_slideOutRightDone {
    @apply
        opacity-0;

    transform: translateX(-40%) translateY(-50%);
}
