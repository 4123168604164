.page {
    @apply
        absolute
        top-0
        left-0
        w-full
        h-full;
}

.pagePadded {
    @apply
        p-8;
}