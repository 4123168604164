.bar {
    @apply
        relative
        h-4
        bg-smoke
        rounded-10px;
}

.rating {
    @apply
        absolute
        top-0
        left-0
        flex
        items-center
        h-full
        border-2
        border-white
        rounded-10px
        box-content;

    top: -2px;
    left: -2px;
    right: -2px;
}

.scoreBar {
    @apply
        ml-auto
        px-2;

    padding-top: 2px;
}

.scoreBarShiftRight {
    margin-left: 100%;
}

.goldStar {
    @apply
        ml-1
        inline;

    margin-top: -2px;
}
