.translationSwitcher {
    position: fixed;
    z-index: 900;

    @apply
        top-6
        right-6
        md:top-12
        md:right-12;
}

.TranslationDialogOptionFlag {
    width:1.5rem;
}