.choice {
    @apply
        flex
        text-left
        items-center
        w-full
        py-2
        px-2.5;
}

.image {
    @apply
        flex-shrink-0
        flex-grow-0;

    width: 3.33rem;
    height: 3.33rem;
}
