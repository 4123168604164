.image {
    @apply
        flex-grow-0
        flex-shrink-0
        border
        border-smoke
        rounded-18px;
}

.description {
    @apply
        mt-1
        overflow-hidden;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
