.wrapper {
    @apply
        flex
        justify-between
        w-full;

    @apply
        md:hidden;
}

.button {
    @apply
        px-4
        py-2
        -mx-4
        -my-2;
}

.chevron {
    @apply
        h-4;
}

.image {
    @apply
        w-12;
}
