/**
 * Animate fade in.
 */
.a_fadeIn {
    opacity: 0;
}

.a_fadeInActive {
    opacity: 1;
    transition: opacity var(--animation-slow, 750ms) var(--animation-bezier, linear);
}
