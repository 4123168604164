/**
 * Tailwind setup.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;


/**
 * SCSS imports
 */

// Layout
@import './layout/container.scss';
@import './layout/flow.scss';

// Components
@import './components/sep-heading.scss';

// Typography
@import './typography/text-heading.scss';
@import './typography/text-label.scss';
@import './typography/text-link.scss';
@import './typography/text-note.scss';
@import './typography/text-paragraph.scss';
@import './typography/text-subheading.scss';
@import './typography/text-subtitle.scss';
@import './typography/wysiwyg.scss';


/**
 * NOTE: Variables with the different speeds of animation are matching the animation_config.ts variables.
 * If you change these please change the JS variables in that file.
 */

:root {
    --animation-bezier: cubic-bezier(0, 0, 0.16, 0.93);
    --animation-fast: 250ms;
    --animation-medium: 500ms;
    --animation-slow: 750ms;
}


/**
 * Body and html css setup.
 */

html {
    @apply
        h-full;

    font-size: 18px;
}

body {
    @apply
        text-paragraph
        text-slate
        overflow-hidden;

    height: 100%;
    min-height: 100%;
    max-height: 100%;
}


/**
 * React root element.
 */
#root {
    @apply
        h-full
        w-full;
}
