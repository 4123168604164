/**
 * Animate item up from center to above the fold.
 */

.a_slideUp {
    transform: translateY(0);
}

.a_slideUpActive {
    transform: translateY(-100%);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideUpDone {
    transform: translateY(-100%);
}


/**
 * Animate item in right to center.
 */

.a_slideInRight {
    transform: translateX(100%);
}

.a_slideInRightActive {
    transform: translateX(0);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideInRightDone {
    transform: translateX(0);
}


/**
 * Animate item in left to center.
 */

.a_slideInLeft {
    transform: translateX(-100%);
}

.a_slideInLeftActive {
    transform: translateX(0);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideInLeftDone {
    transform: translateX(0);
}


/**
 * Animate item out center to left.
 */

.a_slideOutLeft {
    transform: translateX(0);
}

.a_slideOutLeftActive {
    transform: translateX(-100%);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideOutLeftDone {
    transform: translateX(-100%);
}


/**
 * Animate item out center to right.
 */

.a_slideOutRight {
    transform: translateX(0);
}

.a_slideOutRightActive {
    transform: translateX(100%);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideOutRightDone {
    transform: translateX(100%);
}

/**
 * Animate item from invisible to visible.
 */

 .a_fadeUp {
    opacity:0;
}

.a_fadeUpActive {
    opacity:1;
    transition: opacity var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_fadeUpDone {
    opacity:1;
}

/**
 * Animate item from visible to invisible.
 */

.a_fadeDown {
    opacity:1;
}

.a_fadeDownActive {
    opacity:0;
    transition: opacity var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_fadeDownDone {
    opacity:0;
}