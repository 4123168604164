.inner {
    @apply
        my-auto
        h-full
        flex
        flex-col;

    @apply
        md:h-auto;
}

.button {
    @apply
        flex-grow-0
        flex-shrink-0
        mt-4;

    @apply
        mt-8
}
