.Dialog {
    display:block;
    position:fixed;
    z-index:1100;
    top:50%;
    left:50%;
    margin:0;
    background-color:white;
    transform:translateX(-50%);
    box-shadow: 0px 0px 40px rgba(9, 36, 67, 0.12);
    width:18rem;
    max-width:90vw;

    @apply
        space-y-8
        px-4
        py-6
        top-12
        rounded-10px;
}

.DialogHeader {
    display:flex;
    justify-content:space-between;
    align-items:center;
}

.DialogOverlay {
    position:fixed;
    border:none;
    z-index:1000;
    cursor:default;
    top:0; left:0;
    width:100%; height:100%;
    background-color:theme('colors.darkOverlay');
}

/**
 * Transitions
 */

.DialogTransitionEnter {
    opacity:0;
    transform:translate(-50%, -100%);
}

.DialogTransitionEnterActive {
    opacity:1;
    transform:translate(-50%, 0);
    transition: all 300ms var(--animation-bezier, linear);
}

.DialogTransitionEnterDone {
    opacity:1;
    transform:translate(-50%, 0);
}

.DialogTransitionExit {
    opacity:1;
    transform:translate(-50%, 0);
}

.DialogTransitionExitActive {
    opacity:0;
    transform:translate(-50%, -100%);
    transition: all 300ms var(--animation-bezier, linear);
}

.DialogTransitionExitDone {
    opacity:0;
    transform:translate(-50%, -100%);
}

.DialogOverlayTransitionEnter {
    opacity:0;
}

.DialogOverlayTransitionEnterActive {
    opacity:1;
    transition: all 300ms var(--animation-bezier, linear);
}

.DialogOverlayTransitionEnterDone {
    opacity:1;
}

.DialogOverlayTransitionExit {
    opacity:1;
}

.DialogOverlayTransitionExitActive {
    opacity:0;
    transition: all 300ms var(--animation-bezier, linear);
}

.DialogOverlayTransitionExitDone {
    opacity:0;
    transform:translate(-50%, -100%);
}