.inner {
    @apply
        flex
        flex-col
        justify-between
        my-auto
        w-full
        h-full;

    @apply
        md:flex-row
        md:h-auto;
}

.header {
    @apply
        flex-grow-0
        flex-shrink-0;

    @apply
        md:w-1/2;
}

.heading {
    @apply
        mt-7;

    @apply
        md:mt-0;
}

.subheading {
    display:block;
    @apply
        mt-2;
}

.form {
    @apply
        flex
        flex-col
        my-auto
        py-8;

    @apply
        md:w-1/2
        md:m-0
        md:p-0
        md:pl-8;
}

.input {
    @apply
        w-full
        pt-2.5
        pl-4
        pr-8
        pb-2
        rounded-lg;
}
