/**
 * Animate item up from below the fold.
 */

.a_slideUp {
    transform: translateY(100%);
}

.a_slideUpActive {
    transform: translateY(0);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideUpDone {
    transform: translateY(0);
}


/**
 * Animate item down to below the fold.
 */

.a_slideDown {
    transform: translateY(0);
}

.a_slideDownActive {
    transform: translateY(100%);
    transition: transform var(--animation-medium, 500ms) var(--animation-bezier, linear);
}

.a_slideDownDone {
    transform: translateY(100%);
}
