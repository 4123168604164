.wysiwyg {
    @apply
        text-left;

    > * + * {
        @apply
            mt-4;
    }

    h1 {
        @apply
            mt-8
            text-subtitle;
    }

    h2 {
        @apply
            mt-8
            text-heading;
    }

    h3, h4, h5, h6 {
        @apply
            mt-8
            text-heading;
    }

    a {
        @apply
            text-primary
            underline
            hover:no-underline;
    }

    strong {
        @apply
            text-dark;
    }

    ul {
        @apply
            pl-5
            list-disc;

        li + li {
            @apply
                mt-2
        }
    }

    table {
        @apply
            border
            border-dark;
    }

    th {
        @apply
            p-2
            border
            border-dark
            text-dark;
    }

    td {
        @apply
            border
            border-dark
            p-2
            align-top;
    }
}
